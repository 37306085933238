export const publicRoutes = {
  SignIn: '/signin',
}

export const privateRoutes = {
  Addresses: 'adresses',
  Allergens: 'allergens',
  BulkUpload: 'bulk-upload',
  CatalogueUpload: 'catalogue-upload',
  CopyItems: 'copy-items',
  Create: 'create',
  DailyUpdates: 'daily-updates',
  DishTypes: 'dishTypes',
  Dishes: 'dishes',
  Edit: 'edit',
  Home: '/',
  Ingredients: 'ingredients',
  Kitchens: 'kitchens',
  Menus: 'menus',
  NewProductChanges: 'new-products-changes',
  OrderStats: 'order-stats',
  ProductChanges: 'products-changes',
  Products: 'products',
  Recipes: 'recipes',
  Sections: 'sections',
  Staff: 'staff',
  Stocks: 'stocks',
  Suppliers: 'suppliers',
  Units: 'units',
  Users: 'users',
}

export const routes = {
  ...publicRoutes,
  ...privateRoutes,
}
