import React from 'react'

import {
  DashboardIcon,
  KitchenIcon,
  LocalPizzaIcon,
  PublishIcon,
  StorefrontIcon,
  SetMealIcon,
  PersonIcon,
  FormatListBulletedIcon,
  UpdateIcon,
  ContentCopyIcon,
} from 'components'

import TitleProvider from 'contexts/Title'
import { Default as DefaultLayout } from 'layouts'
import { ProductDuplicates } from 'screens/ProductDuplicates'

import { routes } from './Paths'

// const AllergensScreen = React.lazy(() =>
//   import('screens/Allergens').then((module) => ({ default: module.Allergens })),
// )
const BulkUploadScreen = React.lazy(() =>
  import('screens/BulkUpload').then((module) => ({
    default: module.BulkUpload,
  })),
)
const CatalogueUploadScreen = React.lazy(() =>
  import('screens/CatalogueUpload').then((module) => ({
    default: module.CatalogueUpload,
  })),
)
// const DishesScreen = React.lazy(() =>
//   import('screens/Dishes').then((module) => ({ default: module.Dishes })),
// )
// const DishTypesScreen = React.lazy(() =>
//   import('screens/DishTypes').then((module) => ({ default: module.DishTypes })),
// )
// const IngredientsScreen = React.lazy(() =>
//   import('screens/Ingredients').then((module) => ({
//     default: module.Ingredients,
//   })),
// )
const KitchensScreen = React.lazy(() =>
  import('screens/Kitchens').then((module) => ({ default: module.Kitchens })),
)
const CreateKitchenScreen = React.lazy(() =>
  import('screens/Kitchens/Create').then((module) => ({
    default: module.CreateKitchen,
  })),
)
const EditKitchenScreen = React.lazy(() =>
  import('screens/Kitchens/Edit').then((module) => ({
    default: module.EditKitchen,
  })),
)
// const MenusScreen = React.lazy(() =>
//   import('screens/Menus').then((module) => ({ default: module.Menus })),
// )
// const OrderStatsScreen = React.lazy(() =>
//   import('screens/OrderStats').then((module) => ({
//     default: module.OrderStats,
//   })),
// )
const ProductsScreen = React.lazy(() =>
  import('screens/Products').then((module) => ({ default: module.Products })),
)
const EditProductScreen = React.lazy(() =>
  import('screens/Products/Edit').then((module) => ({
    default: module.default,
  })),
)
const RecipesScreen = React.lazy(() =>
  import('screens/Recipes').then((module) => ({ default: module.Recipes })),
)

const ViewRecipeScreen = React.lazy(() => import('screens/Recipes/View'))
const ViewStockScreen = React.lazy(() => import('screens/Stocktake/View'))

// const SectionsScreen = React.lazy(() =>
//   import('screens/Sections').then((module) => ({ default: module.Sections })),
// )
const SuppliersScreen = React.lazy(() =>
  import('screens/Suppliers').then((module) => ({ default: module.Suppliers })),
)

const EditSupplierScreen = React.lazy(() =>
  import('screens/Suppliers/Edit').then((module) => ({
    default: module.default,
  })),
)

const UnitsScreen = React.lazy(() =>
  import('screens/Units').then((module) => ({ default: module.Units })),
)
const UsersScreen = React.lazy(() =>
  import('screens/Users').then((module) => ({ default: module.Users })),
)
const CreateUserScreen = React.lazy(() =>
  import('screens/Users/Create').then((module) => ({
    default: module.CreateUser,
  })),
)
const EditUserScreen = React.lazy(() =>
  import('screens/Users/Edit').then((module) => ({
    default: module.EditUser,
  })),
)
const StaffScreen = React.lazy(() =>
  import('screens/Staff').then((module) => ({ default: module.Staff })),
)
const CreateStaffScreen = React.lazy(() =>
  import('screens/Staff/Create').then((module) => ({
    default: module.CreateStaff,
  })),
)
const EditStaffScreen = React.lazy(() =>
  import('screens/Staff/Edit').then((module) => ({
    default: module.EditStaff,
  })),
)

const DailyUpdatesScreen = React.lazy(() =>
  import('screens/DailyUpdates').then((module) => ({
    default: module.DailyUpdates,
  })),
)

const CopyItemsScreen = React.lazy(() =>
  import('screens/CopyItems').then((module) => ({
    default: module.CopyItems,
  })),
)

export const PrivateRoutes = [
  {
    children: [
      // {
      //   element: <OrderStatsScreen />,
      //   icon: <MonetizationOnIcon />,
      //   name: 'Order Stats',
      //   path: routes.OrderStats,
      // },
      {
        element: <BulkUploadScreen />,
        icon: <PublishIcon />,
        name: 'Bulk Upload',
        path: routes.BulkUpload,
      },
      {
        element: <CatalogueUploadScreen />,
        icon: <PublishIcon />,
        name: 'Catalogue Upload',
        path: routes.CatalogueUpload,
      },
      // {
      //   element: <AllergensScreen />,
      //   icon: <DonutSmallIcon />,
      //   name: 'Allergens',
      //   path: routes.Allergens,
      // },
      // {
      //   element: <DishesScreen />,
      //   icon: <RestaurantIcon />,
      //   name: 'Dishes',
      //   path: routes.Dishes,
      // },
      // {
      //   element: <DishTypesScreen />,
      //   icon: <DashboardIcon />,
      //   name: 'DishTypes',
      //   path: routes.DishTypes,
      // },
      // {
      //   element: <IngredientsScreen />,
      //   icon: <RestaurantMenuIcon />,
      //   name: 'Ingredients',
      //   path: routes.Ingredients,
      // },
      {
        children: [
          {
            element: <KitchensScreen />,
            path: routes.Home,
          },
          {
            element: <CreateKitchenScreen />,
            path: routes.Create,
          },
          {
            element: <EditKitchenScreen />,
            path: `${routes.Edit}/:id`,
          },
          {
            element: <ViewStockScreen />,
            path: `${routes.Stocks}/:id`,
          },
        ],
        icon: <KitchenIcon />,
        name: 'Sites',
        path: routes.Kitchens,
      },
      // {
      //   element: <MenusScreen />,
      //   icon: <MenuBookIcon />,
      //   name: 'Menus',
      //   path: routes.Menus,
      // },
      {
        children: [
          {
            element: <ProductsScreen />,
            path: routes.Home,
          },
          {
            element: <EditProductScreen />,
            path: `${routes.Edit}/:id`,
          },
        ],
        icon: <SetMealIcon />,
        name: 'Products',
        path: routes.Products,
      },
      {
        children: [
          {
            element: <RecipesScreen />,
          },
          {
            element: <ViewRecipeScreen />,
            path: `/:id`,
          },
        ],
        icon: <LocalPizzaIcon />,
        name: 'Recipes',
        path: routes.Recipes,
      },
      // {
      //   element: <SectionsScreen />,
      //   icon: <DashboardIcon />,
      //   name: 'Sections',
      //   path: routes.Sections,
      // },

      {
        children: [
          {
            element: <SuppliersScreen />,
            path: routes.Home,
          },
          {
            element: <EditSupplierScreen />,
            path: `${routes.Edit}/:id`,
          },
        ],
        icon: <StorefrontIcon />,
        name: 'Suppliers',
        path: routes.Suppliers,
      },
      {
        children: [
          {
            element: <UnitsScreen />,
            path: routes.Home,
          },
        ],
        icon: <DashboardIcon />,
        name: 'Units',
        path: routes.Units,
      },
      {
        children: [
          {
            element: <UsersScreen />,
            path: routes.Home,
          },
          {
            element: <CreateUserScreen />,
            path: routes.Create,
          },
          {
            element: <EditUserScreen />,
            path: `${routes.Edit}/:id`,
          },
        ],
        icon: <PersonIcon />,
        name: 'Users',
        path: routes.Users,
      },
      {
        children: [
          {
            element: <StaffScreen />,
            path: routes.Home,
          },
          {
            element: <CreateStaffScreen />,
            path: routes.Create,
          },
          {
            element: <EditStaffScreen />,
            path: `${routes.Edit}/:id`,
          },
        ],
        icon: <PersonIcon />,
        name: 'Staff',
        path: routes.Staff,
      },
      {
        element: <ProductDuplicates />,
        icon: <FormatListBulletedIcon />,
        name: 'Product Matches',
        path: routes.Home,
      },
      {
        element: <DailyUpdatesScreen />,
        icon: <UpdateIcon />,
        name: 'Daily Updates',
        path: routes.DailyUpdates,
      },
      {
        element: <CopyItemsScreen />,
        icon: <ContentCopyIcon />,
        name: 'Copy Items',
        path: routes.CopyItems,
      },
    ],
    element: (
      <TitleProvider>
        <DefaultLayout />
      </TitleProvider>
    ),
    path: routes.Home,
  },
]
