import { ApolloClient, from, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { captureException, captureMessage } from '@sentry/react'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { createUploadLink } from 'apollo-upload-client'
import fetch from 'cross-fetch'
import { decode } from 'jsonwebtoken'

import { API } from 'app'
import {
  clearCurrentUserToken,
  getCurrentUserToken,
  setCurrentUserToken,
} from 'utils'

import { Mutation } from './types'

const retryLink = new RetryLink()

const logError = (error: string) => {
  try {
    captureException(new Error(error))
  } catch (ex) {
    console.log('errorLink: error calling Sentry.captureException', ex)
  }
}

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.map((error) => {
        if (!error.message.toLowerCase().includes('not authorised')) {
          logError(JSON.stringify(error))
        }
        return console.log(
          `[GraphQL error]: Message: ${
            error.message
          }, Location: ${JSON.stringify(
            error.locations,
          )}, Path: ${JSON.stringify(error.path)}`,
        )
      })

      if (
        graphQLErrors.find(
          (e) =>
            e.message.toLowerCase().includes('not authorised') ||
            e.message.toLowerCase().includes('not authorized'),
        )
      ) {
        const oldHeaders = operation.getContext().headers
        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: getCurrentUserToken(),
          },
        })
        return forward(operation)
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${JSON.stringify(networkError)}`)
      return forward(operation)
    }
  },
)

const authorizationLink = setContext((_req, { headers }) => {
  const authorization = getCurrentUserToken()
  const authHeader = authorization ? { authorization: authorization } : {}

  return {
    headers: {
      ...headers,
      ...authHeader,
    } as Headers,
  }
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRefreshToken: () => Promise<any> = async () => {
  const token = getCurrentUserToken()

  if (!token) {
    return null as unknown as Response
  }

  const response = await fetch(`${process.env.REACT_APP_API!}`, {
    body: JSON.stringify({
      query: `
          mutation {
            refresh {
              token
            }
          }
        `,
    }),
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    method: 'POST',
  })

  return response.json()
}

const refreshLink = new TokenRefreshLink({
  accessTokenField: 'newToken',
  fetchAccessToken: getRefreshToken,
  handleError: (error) => {
    console.error('Cannot refresh access token:', error)
  },
  handleFetch: (newToken) => {
    setCurrentUserToken(newToken)
  },
  handleResponse:
    () =>
    (response: {
      data: { refresh: Mutation['refresh'] }
      errors?: unknown[]
    }) => {
      if (!response) {
        try {
          captureMessage(
            'refreshLink handleResponse: response was undefined or null',
          )
        } catch (ex) {
          console.log(
            'refreshLink handleResponse: error calling captureMessage',
          )
        }
        clearCurrentUserToken()
        window.location.reload()

        return
      }

      if (response.errors) {
        clearCurrentUserToken()
        window.location.reload()

        return
      }

      return { newToken: response.data?.refresh?.token }
    },
  isTokenValidOrUndefined: () => {
    const token = getCurrentUserToken()

    if (!token) {
      return true
    }
    // @ts-expect-error
    if (token && decode(token)?.exp * 1000 > Date.now()) {
      return true
    }
    return false
  },
})

const httpLink = createUploadLink({
  credentials: 'include',
  fetch,
  uri: API,
})

const cache = new InMemoryCache()

const link = from([
  authorizationLink,
  refreshLink as any,
  errorLink,
  retryLink,
  httpLink,
])

export const client = new ApolloClient({
  cache: cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
  link: link,
})
